<script setup lang="ts">
const auth = useAuth()
const isLoggingOut = ref(false)

async function logout() {
  isLoggingOut.value = true
  await auth.logout()
  isLoggingOut.value = false
}

const topLevelMenu = computed<NavLink[]>(() => {
  return [
    {
      icon: 'i-heroicons-home',
      to: '/liquidity',
      label: 'Home & RFQs',
      isStrict: true,
    },
    {
      icon: 'i-heroicons-chart-pie',
      label: 'Monitor Active Accounts',
      to: '/liquidity/projects',
      isStrict: true,
    },
    {
      icon: 'i-heroicons-trophy',
      label: 'Leaderboards',
      to: '/liquidity/leaderboards',
      isStrict: true,
    },
    {
      icon: 'i-heroicons-academic-cap',
      label: 'Forgd Academy',
      isStrict: true,
      on: {
        click: () => {
          alert('under development')
        },
      },
    },
  ]
})

const bottomLevelMenu = computed<NavLink[]>(() => {
  return [
    {
      icon: 'i-heroicons-information-circle',
      to: 'https://forgd.com/about',
      label: 'About Forgd',
      external: true,
    },
    {
      icon: 'i-heroicons-chat-bubble-oval-left-ellipsis',
      to: '/support',
      label: 'Support',
    },
    {
      icon: 'i-heroicons-arrow-right-start-on-rectangle',
      label: 'Logout',
      loading: isLoggingOut.value,
      on: {
        click: logout,
      },
    },
  ]
})

const { expanded } = useNavState()
</script>

<template>
  <div
    data-el="AppMenuExpandable"
    class="flex-shrink-0 border-r bg-forgd-primary-900 font-display max-h-screen sticky top-0"
    :style="{ zIndex: 2 }"
  >
    <nav
      class="flex flex-col h-full justify-between divide-y divide-gray-700 max-h-screen"
      :class="expanded ? 'w-[260px]' : 'w-[52px]'"
      aria-label="Site Navigation"
    >
      <div class="min-h-[80px] flex items-center justify-center w-full">
        <NuxtLink to="/">
          <IconForgd :expanded="expanded" />
        </NuxtLink>
      </div>
      <div class="py-4 flex-grow px-2 max-h-full overflow-y-auto min-height-[93px] dark-scrollbar">
        <AppMenuExpandableNavigationList
          :items="topLevelMenu"
        />
      </div>
      <div class="py-4 px-2 opacity-70">
        <AppMenuExpandableNavigationList
          :items="bottomLevelMenu"
        />
      </div>
    </nav>
  </div>
  <AppMenuSlideover :style="{ zIndex: 1 }" :expanded="expanded" />
</template>
